<template>
  <div>
    <CRow>
      <CCol sm="12">
        <TicketDetails :public="false" :validation="true"/>
      </CCol>
    </CRow>
    <loading :active="visible" :can-cancel="true"></loading>
    <CToaster placement="top-end">
      <template v-for="(toast, key) in toasts">
        <CToast :color="toast.color" v-bind="toast" :key="'toast' + key" :show="true">
          {{toast.content}}
        </CToast>
      </template>
    </CToaster>
  </div>
</template>

<script>
import TicketDetails from '../../components/tickets/TicketDetails.vue'
import tickets from '../../services/tickets';
import store from '../../store'
import Vue from 'vue';
import Loading from 'vue-loading-overlay/dist/vue-loading.js';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: 'ValidationTicket',
  components: { TicketDetails, Loading },
  data: function () {
		return {
      
    }
  },
  mounted: async function() {
    this.loading();
    
    this.loaded();
  },
  methods: {
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  },
  computed: {
    toasts () {
      return store.state.toasts;
    },
    visible() {
      return store.state.isLoading;
    }
  }
}
</script>
